<template>
  <q-dialog v-model="dialog" persistent>
    <q-card flat style="min-width: 456px">
      <q-toolbar class="bg-white text-dark">
        <q-toolbar-title> Tag </q-toolbar-title>
        <q-btn flat round dense icon="mdi-close" @click="onClose" />
      </q-toolbar>
      <q-toolbar class="bg-white text-dark">
        <q-toolbar-title>
          Criativo - {{ creativeData.id }} | Campanha:
          {{ creativeData.campaign_name }}
        </q-toolbar-title>
      </q-toolbar>

      <q-card-section>
        <div class="row">
          <div class="col-12 q-pt-sm">
            <q-select
              ref="channelSelect"
              v-model="selectedChannels"
              :options="channelFiltered"
              multiple
              label="Selecionar Canais"
              outlined
              use-chips
              @filter="filterChannels"
              use-input
              option-label="site_name"
              option-value="site_id"
            >
              <template v-slot:before-options>
                <q-item
                  clickable
                  @click="
                    () => {
                      if (selectedChannels === channelFiltered) {
                        selectedChannels = [];
                      } else {
                        selectedChannels = channelFiltered;
                        $refs.channelSelect.hidePopup();
                      }
                    }
                  "
                >
                  <q-item-section class="text-bold"> Selecionar todos </q-item-section>
                </q-item>
              </template>
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-italic text-grey"> Não existem canais disponíveis </q-item-section>
                </q-item>
              </template>
              <template v-slot:selected-item="scope">
                <q-chip
                  removable
                  size="0.8rem"
                  @remove="scope.removeAtIndex(scope.index)"
                  :tabindex="scope.tabindex"
                  color="primary"
                  square
                  icon-remove="close"
                  text-color="white"
                  input-class="text-caption"
                >
                  {{ scope.opt.site_name }}
                </q-chip>
              </template>
            </q-select>
          </div>
        </div>
      </q-card-section>
      <!-- <q-separator spaced inset /> -->
      <q-card-actions align="right" class="q-mr-sm">
        <q-btn unelevated outline no-caps label="Cancelar" class="q-px-md" color="primary" @click="onClose" />
        <q-btn
          v-if="creativeSelect?.type_name != 'emailmkt'"
          :disable="disableBtnExport"
          unelevated
          no-caps
          label="Exportar csv"
          class="q-px-md"
          color="primary"
          @click="exportTableCsv(shortLinks, columnsCsv, `CriativoID-${creativeData.id}`)"
        />
        <q-btn
          unelevated
          class="q-px-md"
          no-caps
          :loading="loading"
          label="Gerar tag(s)"
          color="primary"
          @click="onGenerate"
          :disable="selectedChannels === null || selectedChannels.length === 0"
        />
      </q-card-actions>
      <q-card-section v-for="(shortLink, index) in shortLinks" :key="index">
        <q-separator spaced />
        <q-item v-if="shortLink.shortLink.destination_url">
          <q-item-section>
            <q-item-label overline>{{ shortLink.siteName }}</q-item-label>
            <q-item-label>
              <q-input
                outlined
                label="URL de redirecionamento"
                readonly
                type="text"
                :value="shortLink.shortLink.destination_url"
              />
            </q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-btn
              round
              color="primary"
              @click="clickToCopy(shortLink.shortLink.destination_url)"
              icon="content_copy"
            />
          </q-item-section>
        </q-item>
        <q-item v-if="shortLink.shortLink.image_url">
          <q-item-section>
            <q-item-label overline>{{ shortLink.siteName }}</q-item-label>
            <q-item-label>
              <q-input outlined label="URL da imagem" readonly type="text" :value="shortLink.shortLink.image_url" />
            </q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-btn round color="primary" @click="clickToCopy(shortLink.shortLink.image_url)" icon="content_copy" />
          </q-item-section>
        </q-item>
        <q-item v-if="shortLink.shortLink.html">
          <q-item-section>
            <q-item-label overline>{{ shortLink.siteName }}</q-item-label>
            <q-item-label v-if="shortLink.shortLink.html">
              <q-input readonly :value="shortLink.shortLink.html" type="textarea" label="Html" outlined>
                <template v-slot:append></template>
              </q-input>
            </q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-btn round color="primary" @click="clickToCopy(shortLink.shortLink.html)" icon="content_copy" />
          </q-item-section>
        </q-item>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import CreativeMixin from '@/mixins/CreativeMixin';
import CreativesService from '../../services/CreativesService';
import AdvertiserService from '../../services/AdvertiserService';

export default {
  mixins: [CreativesService, CreativeMixin, AdvertiserService],
  data() {
    return {
      active: false,
      dialog: false,
      creativeData: {
        campaign_name: null,
        id: null
      },
      dialogFullScreen: false,
      maximizedToggle: true,
      channelList: [],
      channelFiltered: [],
      selectedChannels: null,
      shortLinks: [],
      loading: false,
      columnsCsv: []

    };
  },

  computed: {
    disableBtnExport: {
      get() {
        return this.shortLinks.length === 0;
      }
    }
  },

  props: {
    id: {
      type: [Number, String],
      default: null
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    initializeColumns() {
      if (this.shortLinks.length > 0) {
        const firstRow = this.shortLinks[0].shortLink;
        this.columnsCsv = [
          {
            name: 'id_site',
            label: 'Id do Canal',
            field: (row) => row.shortLink.id_site
          },
          {
            name: 'siteName',
            label: 'Nome do Canal',
            field: 'siteName'
          }
        ];
        if (firstRow.destination_url) {
          this.columnsCsv.push({
            name: 'destination_url',
            label: 'Link Encurtado',
            field: (row) => row.shortLink.destination_url
          });
        }
        if (firstRow.image_url) {
          this.columnsCsv.push({
            name: 'image_url',
            label: 'Link Imagem',
            field: (row) => row.shortLink.image_url
          });
        }
      }
    },

    filterChannels(val, update) {
      if (val === '') {
        update(() => {
          this.channelFiltered = this.channelList;
        });
        return;
      }
      update(() => {
        const needle = val.toLowerCase();
        this.channelFiltered = this.channelList.filter((v) => v.site_name.toLowerCase().indexOf(needle) > -1);
      });
    },
    onClose() {
      this.ActionSetCreativeSelect('');
      this.dialog = false;
      this.shortLinks = [];
      this.options = [];
      this.selectedChannels = null;
      this.$router.push({ name: 'CreativeList' });
    },

    onSave() {},

    openFullScreen() {
      this.dialogFullScreen = !this.dialogFullScreen;
    },

    onGenerate() {
      const hasRestrictedChannels = this.creativeData.id_site && this.creativeData.id_site.trim() !== '';

      if (!hasRestrictedChannels) {
        this.selectedChannels.map(this.generateTag);
        return;
      }

      const restrictedChannels = this.creativeData.id_site.split(',').map((id) => parseInt(id));

      const hasNonRestrictedChannels = this.selectedChannels.some(
        (channel) => !restrictedChannels.includes(parseInt(channel.site_id))
      );

      if (hasNonRestrictedChannels) {
        this.$q
          .dialog({
            title: 'Alerta',
            message:
              'Esse é um criativo restrito. Pode existir 1 ou mais canais selecionados que não estão inclusos na restrição desse criativo. Deseja continuar?',
            persistent: true,
            ok: true,
            cancel: 'Cancelar'
          })
          .onOk(() => {
            this.selectedChannels.map(this.generateTag);
          });
      } else {
        this.selectedChannels.map(this.generateTag);
      }
    },

    async getChannelsApproved() {
      try {
        const { data, status } = await this.listSitesApprovedDetailsByCampaign(this.creativeData.id_campaign);
        if (status == 200) {
          this.channelList = data;
          this.channelFiltered = data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getCreativeById(id) {
      try {
        const { data, status } = await this.getCreativeId(id);
        if (status == 200) {
          this.creativeData = data.creatives;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async generateTag(value) {
      this.loading = true;

      const existingShortLink =
        this.shortLinks.length > 0 ? this.shortLinks.map((item) => parseInt(item.shortLink.id_site)) : [];
      if (!existingShortLink.includes(value.site_id)) {
        try {
          const { data, status } = await this.getShortned(this.id, value.site_id);
          if (status == 200) {
            this.shortLinks.push({
              shortLink: data,
              siteName: this.channelList.find((item) => item.site_id == data.id_site).site_name
            });
          }
        } catch (error) {
          this.errorNotify('Não foi possível gerar tags. Verifique por urls com erro no criativo.');
        }
      }
      this.loading = false;
    }
  },

  watch: {
    id() {
      if (this.id !== null) {
        this.dialog = true;
        this.shortLinks = [];
        this.options = [];
        this.selectedChannels = null;
        this.getCreativeById(this.id);
      }
    },

    creativeData() {
      if (typeof this.creativeData.id_campaign !== undefined) {
        this.getChannelsApproved();
      }
    },

    shortLinks: {
      handler() {
        this.initializeColumns();
      },
      immediate: true
    }
  },
  created() {
    if (this.id !== null) {
      this.dialog = true;
      this.shortLinks = [];
      this.options = [];
      this.selectedChannels = null;
      this.getCreativeById(this.id);
    }
  }
};
</script>

<style></style>
